<template>
  <section class="container970">
    <div class="clubTop">
      <el-image :src="backgroundImage"></el-image>
      <el-button type="primary" @click="$router.push('/contactClub')" round class="contact_pc">
        <i class="el-icon-phone"></i> Contact
      </el-button>
    </div>
    <el-card class="clubInfo">
      <h4>
        JCI Club 100
      </h4>
      <p v-html="intro"></p>
      <el-button type="primary" @click="$router.push('/becomeMember')" round class="become_mobile">
        Become a CLUB 100 Member</el-button>
      <el-button type="primary" @click="$router.push('/contactClub')" round class="contact_mobile">
        <i class="el-icon-phone"></i> Contact
      </el-button>
    </el-card>
    <div class="tabs">
      <div :class="activeTab == 4 ? 'active' : ''" @click="checkTab(4)">
        PLATINUM
      </div>
      <div :class="activeTab == 3 ? 'active' : ''" @click="checkTab(3)">
        GOLD
      </div>
      <div :class="activeTab == 2 ? 'active' : ''" @click="checkTab(2)">
        SILVER
      </div>
      <div :class="activeTab == 1 ? 'active' : ''" @click="checkTab(1)">
        BRONZE
      </div>
    </div>
    <section v-loading="loading">
      <b-row>
        <b-col v-for="(item, index) in clubList" :key="index" md="6" lg="4">
          <iq-card body-class="p-0" class="iqCard">
            <div class="image_box" @click="$router.push(`/clubDet?id=${item.id}`)">
              <el-image style="
                width: 100%;
                height: 156.5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              " fit="cover" :src="item.company_logo">
              </el-image>
            </div>
            <div class="userDetail">
              <div class="title-date">
                <h4 class="title">
                  {{ item.company_name }}
                </h4>
                <!-- <p class="date m-0">Since 2023</p> -->
              </div>
            </div>
          </iq-card>
        </b-col>
      </b-row>
      <div v-if="!clubList.length">
        <div class="d-flex justify-content-center" style="background:#fff;padding: 50px">
          <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
        </div>
      </div>
    </section>
    <div class="d-flex justify-content-end">
      <el-pagination background layout="prev, pager, next" :page-size="size" @current-change="handleCurrentChange"
        :current-page.sync="page" :total="total" hide-on-single-page>
      </el-pagination>
    </div>
  </section>
</template>
<script>
  export default {
    data() {
      return {
        backgroundImage: require("@/assets/images/group/club_banner.jpg"),
        activeTab: 4,
        intro: "",

        loading: false,
        clubList: [],
        page: 1,
        total: 0,
        size: 12
      };
    },

    components: {},

    created() {
      this.getClub100List();
      this.getClub100Config();
    },

    methods: {
      getClub100Config() {
        this.$http.getClub100Config().then(res => {
          this.intro = res.data.introduction;
        });
      },
      checkTab(val) {
        this.activeTab = val;
        this.page = 1;
        this.getClub100List();
      },
      async getClub100List() {
        this.loading = true;
        let res = await this.$http.getClub100List({
          membership_level: this.activeTab,
          per_page: this.size,
          page: this.page
        });
        this.loading = false;
        if (res.status == 200) {
          this.clubList = res.data.data;
          this.total = res.data.total;
        }
      },
      handleCurrentChange(page) {
        this.page = page;
        this.getClub100List();
      }
    }
  };
</script>
<style lang="scss" scoped>
  @media(max-width: 768px) {
    .contact_pc {
      display: none;
    }

    .become_mobile {
      width: 248px;
    }

    .contact_mobile {
      width: 248px;
      margin-left: 0px !important;
      margin-top: 15px;
    }
  }

  @media not screen and (max-width: 768px) {
    .contact_mobile {
      display: none;
    }
  }

  .container970 {
    .el-card {
      margin-bottom: 15px;
    }

    .clubTop {
      position: relative;

      ::v-deep .el-card__body {
        height: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .el-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }

    .clubInfo {
      text-align: center;
    }

    .tabs {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 15px;

      div {
        width: 25%;
        height: 74px;
        text-align: center;
        line-height: 74px;
        font-size: 18px;
        font-weight: 400;
        color: #aaa;
      }

      :nth-child(1) {
        background-image: linear-gradient(165deg,
            #809bb0,
            #e5ebf0 15%,
            #aebfcd 30%,
            #fdfdfe 50%,
            #adbfca 80%,
            #809bb0);
      }

      :nth-child(2) {
        background-image: linear-gradient(155deg,
            #fbe67b,
            #fdfae1 40%,
            #f8d255 60%,
            #fbe67b);
      }

      :nth-child(3) {
        background-image: linear-gradient(165deg, #a2a5a8, #fdfae1 60%, #a2a5a8);
      }

      :nth-child(4) {
        background-image: linear-gradient(165deg, #e17b39, #fdfae1 60%, #e17b39);
      }
    }

    .active {
      color: #000000 !important;
      font-weight: 700 !important;
    }

    .iqCard {
      padding: 10px;
    }
  }
</style>